import React, { useEffect } from 'react'

import '../Login/login.css';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Login = () => {
    const [user, setUser] = React.useState('');
    const [error, setError] = React.useState('');
    const [eye, setEye] = React.useState(false);
    const [dados, setDados] = React.useState(false);
    const inputLogin = React.useRef(null);
    const inputPassword = React.useRef(null);
    const [passwordType, setPasswordType] = React.useState('password');

    const handleLogin = () => {
        if (user.length <= 4) {
            setError('favor, O campo precisa ser preenchido corretamente');
            inputLogin.current.focus();
        } else {
            setUser("");
            setError('');
            inputPassword.current.focus();
        }
    };
    useEffect(() => {
        inputLogin.current.focus();
    }, [])

    const handleClickEye = () => {
        console.log('Clicou no ícone de olho');
        setEye(prevEye => !prevEye);
        setPasswordType(prevType => prevType === 'password' ? 'text' : 'password')
    }

    const hadleClick = () => {
        setDados('Os dados não conferem');
    }

    return (
        <React.Fragment>
            <div className="containerLogin">
                <div className="box">
                    <h1 className='h1'>Entre com seu usuário e senha</h1>
                    <div className="dados">
                        <p className='user'>user</p>
                        <input
                            className='user'
                            ref={inputLogin}
                            type="text"
                            onBlur={handleLogin}
                            onChange={(e) => setUser(e.target.value)}
                        />

                        <p className='user describe'>{error}*</p>
                        <p className='user'>password</p>

                        <div className='campoInput'>
                            <input
                                className='inputSenha'
                                ref={inputPassword}
                                type={passwordType} />
                            <div className="onOffEye" onClick={handleClickEye}>
                                {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </div>

                        </div>
                        <p className='user'>{dados}</p>
                        <button className='user' onClick={hadleClick} >Entrar</button>
                    </div>

                </div>

            </div>
        </React.Fragment >
    )
}
export default Login