import React from 'react';


import Test from '../Test/test.jsx'


const Return = ({ color }) => {

    return (
        <>
            <div className="all">
                <h1>Chegando a cor</h1>
                <p>Mostrar a cor aqui: {color} </p>

            </div>
        </>
    )
}
export default Return;