import React, { useEffect } from "react";
import Logo from "../../Img/logovetorizadoKZ.png";
import Telefonista from "../../Img/telefonista.jpg";
import Mapa from "../../Img/mapa.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeIcon from '@mui/icons-material/Home';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import WcIcon from '@mui/icons-material/Wc';
import CallIcon from '@mui/icons-material/Call';

import "../Contact/contact.desktop.css";
import "../Contact/contact.mobile.css";

function Contact() {
    function iconInsta() {
        const insta = document.querySelector(".insta");
        if (insta) {
            window.open("https://www.instagram.com/kamisariazanuto/");
        }
    }
    function iconFace() {
        const face = window.document.querySelector(".face");
        if (face) {
            window.open("https://web.facebook.com/kamisaria");
        }
    }
    function iconYoutube() {
        const youtube = window.document.querySelector(".youtube");
        if (youtube) {
            window.open("https://www.youtube.com/watch?v=-qwyq3HSuSY");
        }
    }
    function iconPinterest() {
        const pinterest = window.document.querySelector(".pinterest");
        if (pinterest) {
            window.open("https://br.pinterest.com/");
        }
    }
    function iconWhats() {
        const whats = window.document.querySelector(".whats");
        if (whats) {
            window.open("https://web.whatsapp.com/");
        }
    }
    function locationMap() {
        const urlMapa = document.querySelector(".divMaps img");

        if (urlMapa) {
            window.open("https://bit.ly/kamisaria-zanuto");
        }
    }
    function DropDownMan() {
        const subman = document.querySelector(".man");
        if (subman) {
            window.open("http://localhost:3001/man");
        }
    }
    function DropDownWoman() {
        const subWoman = document.querySelector(".woman");
        if (subWoman) {
            window.open("http://localhost:3001/woman");
        }
    }
    return (
        <header className="headContainner">
            <nav className="divNav">
                <div className="divLeft"></div>
                <div className="divCenter">
                    <section className="SectionLogotipo">
                        <img className="logokz" src={Logo} alt="" />
                    </section>
                    <article className="textKamisariaZanuto">
                        <h1>KAMISARIA ZANUTO</h1>
                    </article>
                </div>
                <div className="divRight">
                    <div className="divMenu">
                        <ul className="divUl">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <div className="traits">
                                <p>|</p>
                            </div>
                            <li>
                                <a href="/">História</a>
                            </li>
                            <div className="traits">
                                <p>|</p>
                            </div>
                            <li className="liComprar">
                                <a href="/" onClick={""}>Loja</a>
                            </li>
                            <div className="traits">
                                <p>|</p>
                            </div>
                            <li>
                                <a href="/">Vestuário</a>
                                <div className="dropDown-subMenu">
                                    <ul>
                                        <li className="man" onClick={''}>
                                            <a href="/">
                                                <h1> Camisa Masc.</h1>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="/">
                                                <h1>Camisa Fem.</h1>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <h1>Medidas</h1>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <div className="traits">
                                <p>|</p>
                            </div>
                            <li className="liContato">
                                <a href="/" onClick={''}>
                                    Contato
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="separate">
                <section className="section-container">
                    <div className="grid-left">
                        <div className="container_ContactDataForm">
                            <section className="info-login">
                                <p className="text-tittle">Entre em contato conosco</p>
                                <section className="iDataClints">
                                    <label htmlFor="">Nome:</label>
                                    <input
                                        type="text"
                                        placeholder="Digite seu nome!"
                                    />
                                </section>
                                <section className="iDataClints">
                                    <label htmlFor="">E-mail:</label>
                                    <input
                                        type="text"
                                        placeholder="Digite seu e-mail!"
                                    />
                                </section>

                                <div className="divButtonContact">
                                    <button className="bottonToSend">enviar</button>
                                </div>

                            </section>
                            <div className="_areaContact">
                                <textarea
                                    className="messageArea"
                                    placeholder="Digite sua mensagem aqui..."                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className="grid-right">
                        <section className="info-photo">
                            <img className="img-telefonista" src={Telefonista} alt="" />
                        </section>
                    </div>
                </section>
            </section>
            <footer className="_footerContainer">
                <section className="_footer_HomeRowsOne">
                    <section className="_footer_home_columnOneLeft">
                        <section className="_footer_home_description">
                            <p>
                                Razão Social: Kamisaria zanuto - ME Confecção de
                                camisa sob medida - Nosso endereço: Praça General
                                Gentil Falcão, número 49 - Bairro: Cidade Monções -
                                Brooklin - São Paulo - SP - Cep 04571-150 - SP.
                                Telefone: (11) 5506-8369 / whats-app (11)
                                93240-6348. Nosso horário comercial é de,
                                Segunda-feira a Sexta-feira das 9h ás 16:30hs e de
                                Sábado das 9h ás 13h.
                            </p>
                        </section>
                    </section>
                    <section className="_footer_home_column_OneRight">
                        <div className="_click" onClick=
                            {locationMap}>
                            <img className="_imgsMapa" src={Mapa} alt="" />
                        </div>
                    </section>
                </section>
                <section className="_footer_home_column_redesociais">
                    <div className="divTextName">
                        <p>Siga a kamisaria zanuto</p>
                    </div>
                    <div className="iconsSociais">
                        <a
                            href="/"
                            className="insta icon-rede"
                            onClick={iconInsta}
                        >
                            <InstagramIcon />
                        </a>
                        <a
                            href="/"
                            className="face icon-rede"
                            onClick={iconFace}
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href="/"
                            className="youtube icon-rede"
                            onClick={iconYoutube}
                        >
                            <YouTubeIcon />
                        </a>
                        <a
                            href="/"
                            className="pinterest icon-rede"
                            onClick={iconPinterest}
                        >
                            <PinterestIcon />
                        </a>
                        <a
                            href="/"
                            className="whats icon-rede"
                            onClick={iconWhats}
                        >
                            <WhatsAppIcon />
                        </a>
                    </div>
                </section>
                <div className="finishBoard">
                    <div className="divEnd">
                        <p> &copy; Fundada desde: 1974</p>
                        <p className="adress-site"> www.kamisariazanuto.com.br</p>
                    </div >
                </div>
            </footer>
        </header>
    );
}
export default Contact;
