import React from "react";
import "../ShirtWoman/style.css";

function shirtWoman() {
    return (
        <div>
            <h1>init</h1>
        </div>
    );
}
export default shirtWoman;
