import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';


import '../Modal/guiaTamanho.desktop.css';
import '../Modal/guiaTamanho.mobile.css';




const GuiaTamanho = ({ guia, setGuia }) => {
    const navigate = useNavigate();


    const handleClick = () => {
        navigate("/sales");
    }
    return (
        <>
            <div className="ContainerModalGuiaTamanho">
                <nav className="_naveMeasure">
                    <section onClick={handleClick}>
                        <KeyboardReturnIcon className="custom-icon" />
                    </section>
                    <p>Voltar</p>
                </nav>
                <div className="containerGuiaTamanho">
                    <section>Guia de Tamanhos</section>
                </div>
                <div className="guiaDescriptionMeasures _unique ">
                    <section className="number_bold"></section>
                    <section>Colar</section>
                    <section>Pala</section>
                    <section>Manga</section>
                    <section>Tórax</section>
                    <section>Cintura</section>
                    <section>Quadril</section>
                    <section>Cumpr.</section>
                    <section>Punho</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">P/P</section>
                    <section>38</section>
                    <section>42</section>
                    <section>61</section>
                    <section>102</section>
                    <section>98</section>
                    <section>100</section>
                    <section>73.</section>
                    <section>26</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">P</section>
                    <section>40</section>
                    <section>44</section>
                    <section>62</section>
                    <section>104</section>
                    <section>100</section>
                    <section>102</section>
                    <section>74</section>
                    <section>26</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">M</section>
                    <section>42</section>
                    <section>46</section>
                    <section>63</section>
                    <section>106</section>
                    <section>102</section>
                    <section>104</section>
                    <section>75</section>
                    <section>26</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">G</section>
                    <section>44</section>
                    <section>42</section>
                    <section>64</section>
                    <section>108</section>
                    <section>104</section>
                    <section>106</section>
                    <section>76</section>
                    <section>26</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">G/G</section>
                    <section>46</section>
                    <section>42</section>
                    <section>64</section>
                    <section>108</section>
                    <section>104</section>
                    <section>106</section>
                    <section>76</section>
                    <section>26</section>
                </div>
                <div className="guiaDescriptionMeasures">
                    <section className="number_bold">E/G</section>
                    <section>48</section>
                    <section>42</section>
                    <section>64</section>
                    <section>108</section>
                    <section>104</section>
                    <section>106</section>
                    <section>76</section>
                    <section>26</section>
                </div>
            </div>
        </>
    )
}
export default GuiaTamanho;