import React, { useEffect, useState } from "react";
import axios from "axios";


import "../Modal/cep.desktop.css"


const ModalCep = ({ cep, closeModal }) => {
    const [loading, setLoading] = useState(true);
    const [addressData, setAddressData] = useState(null);


    useEffect(() => {
        // Função para buscar dados da API ViaCEP
        const fetchAddressData = async () => {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                setAddressData(response.data);

            } catch (error) {
                console.error("Erro ao buscar dados do CEP:", error);
                setAddressData(null);
            } finally {
                setLoading(false);
            }
        };
        if (cep) {
            fetchAddressData()
        }
    }, [cep]);


    return (
        <>
            <div className="modal-overlay" onClick={closeModal}></div>
            <div className="modalCep">
                <div className="modal-content">
                    <div className="closeModal" onClick={closeModal} >X</div>
                    <h2>CEP Informado</h2>
                    <p className="_cepNumber"><strong>CEP:</strong> {cep}</p>

                    {loading ? (
                        <p>Carregando dados...</p>
                    ) : addressData ? (
                        <div>
                            <p> <strong>Estado:</strong>{addressData.logradouro} </p>
                            <p> <strong>Logradouro:</strong>{addressData.bairro} </p>
                            <p> <strong>Bairro:</strong>{addressData.localidade} </p>
                            <p> <strong>Cidade:</strong>{addressData.uf} </p>
                        </div>
                    ) : (
                        <p>Não foi possível carregar os daods do cep.</p>
                    )}

                </div>

            </div>
        </>
    )
}
export default ModalCep;

// <div className="container_viaCep">
// <section>
//     <label htmlFor="">Endereço.</label>
//     <input type="text" />
// </section>
// </div>